import React from 'react';
import styled from 'styled-components';
import RichText from '@shipae/components-sandbox/component/rich-text';
import RichTextTheme
  from '@shipae/components-sandbox/component/rich-text/theme';
import ReactMarkdown from 'markdown-to-jsx';
import { Image } from 'src/infrastructure/gatsby/types/image';
import { Svg } from 'src/infrastructure/gatsby/types/svg-type';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { fonts, media } from 'src/views/theme';
import { calculateColumnWidth } from './utils';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 2.4rem;
`;

interface ColumnProps {
  columnCount: number;
}

const Column = styled.div<ColumnProps>`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: ${ (pr) => calculateColumnWidth(pr.columnCount) };

  ${ media.md } {
    flex-grow: 0;
    flex-basis: calc(50% - 4rem);
  }

  ${ media.max(600) } {
    flex-basis: 100%;
  }

  margin-bottom: 4rem;

  &:not(:nth-child(${ (pr) => pr.columnCount }n)) {
    margin-right: 2rem;
  }

  ${ media.md } {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  ${ media.max(600) } {
    margin-left: 0;
    margin-right: 0;
  }
`;

const FullWidthIcon = styled.div`
  width: 100%;
  height: 28rem;
  ${ media.max(600) } {
    height: 60rem;
  }
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const CustomRichTextTheme = {
  ...RichTextTheme,
  Main: styled(RichTextTheme.Main)`
    p:not(:last-child) {
      margin-bottom: 2rem;
    }
    a, a:visited, a:active {
      ${ fonts.bodyl200 };
      ${ media.max(600) } {
        ${ fonts.displayxs200 };
      }

      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    img {
      display: inline-block;
      margin-right: 0.5rem;
    }

    p {
      img + a {
        display: inline-block;
        transform: translateY(-0.5rem);
      }
    }
  `,
};

interface IProps {
  columns: {
    id: string;
    content?: string;
    fallbackImage?: Svg & Image;
  }[]
}

const Columns: React.FC<IProps> = ({
  columns,
}) => {
  const renderFallbackImage = (image?: Image & Svg): JSX.Element | null => {
    if (!image) return null;

    if (image.localFile?.childImageSharp) {
      return (
        <GatsbyImage
          fluid={image.localFile?.childImageSharp?.fluid}
          alt={image.alternativeText || ''}
          objectFit="contain"
          objectPosition="center top"
        />
      );
    } if (image.localFile?.publicURL) {
      return (
        <FullWidthIcon
          style={{
            backgroundImage: `url('${ image.localFile?.publicURL }')`,
          }}
          role="img"
          aria-label={image.alternativeText || ''}
        />
      );
    }

    return null;
  };
  return (
    <FlexContainer>
      {columns.map((column) => (
        <Column
          key={column.id}
          columnCount={columns.length}
          data-test="columns-section-column"
        >
          {column.content ? (
            <RichText theme={CustomRichTextTheme}>
              <ReactMarkdown options={{
                ...gloabalReactMdOptions,
                forceBlock: false,
              }}
              >
                {column.content}
              </ReactMarkdown>
            </RichText>
          ) : renderFallbackImage(column.fallbackImage)}
        </Column>
      ))}
    </FlexContainer>
  );
};

export default Columns;
