/**
 * TODO: Move to Components Sandbox
 */
import React, {
  RefObject, useRef,
} from 'react';
import ReactMarkdown from 'markdown-to-jsx';

import {
  VerticalScrollSlides as Model,
} from 'infrastructure/gatsby/types/vertical-scroll-slides';
import {
  Section,
  Text,
  Title,
  InnerFlex,
  Controls,
  Control,
  Content,
  RichTextEx,
  MarkdownWrapper,
  ImageWrapper,
  Image,
  ImageRef,
} from './theme';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

type IProps = Omit<Model, 'id' | 'type' | 'mobileView'>;

const VerticalScrollSlides: React.FC<IProps> = ({
  sectionId,
  title,
  accentHexColor,
  verticalSlides,
}) => {
  const sectionRef = useRef(null);
  const textRefs = useRef<RefObject<HTMLDivElement>[]>(
    verticalSlides.map(
      () => React.createRef<HTMLDivElement>(),
    ),
  );
  const controlRefs = useRef<RefObject<HTMLHeadingElement>[]>(
    verticalSlides.map(
      () => React.createRef<HTMLHeadingElement>(),
    ),
  );
  const imageRefs = useRef<RefObject<HTMLDivElement>[]>(
    verticalSlides.map(
      () => React.createRef<HTMLDivElement>(),
    ),
  );

  return (
    <Section
      id={sectionId}
      ref={sectionRef}
      data-test="vertical-scroll-slides"
    >
      <Text>
        <Title>
          {title}
        </Title>
        <InnerFlex>
          <Controls topPosition={title.length > 35 ? 24 : 18}>
            {verticalSlides.map((slide, i) => (
              <Control
                key={slide.id}
                accentColor={accentHexColor}
                ref={controlRefs.current[i]}
              >
                {slide.clickableTitle}
              </Control>
            ))}
          </Controls>
          <Content data-test="vertical-scroll-slides-content">
            <RichTextEx>
              {verticalSlides.map((slide, i) => (
                <MarkdownWrapper ref={textRefs.current[i]}>
                  <ReactMarkdown
                    options={gloabalReactMdOptions}
                  >
                    {slide.slideContent || ''}
                  </ReactMarkdown>
                </MarkdownWrapper>
              ))}
            </RichTextEx>
          </Content>
        </InnerFlex>
      </Text>
      <ImageWrapper>
        {verticalSlides.map((slide, i) => (
          slide.slideImage.localFile?.childImageSharp?.fluid && (
            <ImageRef
              ref={imageRefs.current[i]}
            >
              <Image
                fluid={slide.slideImage.localFile?.childImageSharp?.fluid}
                alt={slide.slideImage.alternativeText || ''}
                objectFit="contain"
                objectPosition="center center"
              />
            </ImageRef>
          )
        ))}
      </ImageWrapper>
    </Section>
  );
};

export default VerticalScrollSlides;
