import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import GatsbyImage from 'gatsby-image/withIEPolyfill';

import MinimalAccordionTemplate
  from '@shipae/components-sandbox/component/minimal-accordion';
import RichText
  from '@shipae/components-sandbox/component/rich-text';

import {
  VerticalScrollSlides as Model,
} from 'infrastructure/gatsby/types/vertical-scroll-slides';
import { Heading } from '../../shared/heading';
import { MobileSection } from '../../shared/mobile-section';
import Columns from '../../columns/template';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

type IProps = Omit<Model, 'id' | 'type'>;

const MobileView: React.FC<IProps> = ({
  sectionId,
  title,
  verticalSlides,
  mobileView,
}) => (
  <MobileSection id={sectionId}>
    <Heading as="div">{title}</Heading>
    {
    mobileView === 'accordion' ? (
      <MinimalAccordionTemplate
        entries={verticalSlides.map((slide) => ({
          title: slide.clickableTitle || '',
          content: (
            <>
              <RichText>
                <ReactMarkdown
                  options={gloabalReactMdOptions}
                >
                  {slide.slideContent || ''}
                </ReactMarkdown>
              </RichText>
              {slide.slideImage.localFile?.childImageSharp?.fluid ? (
                <GatsbyImage
                  fluid={slide.slideImage.localFile?.childImageSharp?.fluid}
                  alt={slide.slideImage.alternativeText || ''}
                  objectFit="contain"
                  objectPosition="center center"
                />
          ) : null}
            </>
          ),
        }))}
      />
    ) : (
      <Columns columns={verticalSlides.map((slide) => ({
        id: slide.id,
        content: slide.slideContent,
      }))}
      />
    )
  }
  </MobileSection>
);

export default MobileView;
