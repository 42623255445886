import React from 'react';
import {
  VerticalScrollSlides as IProps,
} from 'infrastructure/gatsby/types/vertical-scroll-slides';
import VerticalScrollDesktop from './template';
import MobileView from './mobile';

const VerticalScrollSlides: React.FC<IProps> = ({
  title,
  accentHexColor,
  verticalSlides,
  mobileView,
}) => (
  <>
    <VerticalScrollDesktop
      title={title}
      accentHexColor={accentHexColor}
      verticalSlides={verticalSlides}
    />
    <MobileView
      title={title}
      accentHexColor={accentHexColor}
      verticalSlides={verticalSlides}
      mobileView={mobileView}
    />
  </>
);

export default VerticalScrollSlides;
