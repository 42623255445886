import RichText from '@shipae/components-sandbox/component/rich-text';
import styled from 'styled-components';
import { g100 } from '@shipae/components-sandbox/component/colors';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { GatsbyImageProps } from 'gatsby-image';
import { fonts, media } from 'src/views/theme';
import { DesktopSection } from '../../shared/desktop-section';

export const Section = styled(DesktopSection)`
  position: relative;
  display: flex;
  align-items: space-between;

  ${ media.max(767) } {
    display: none;
  }

  background-color: ${ g100() };

  padding: 0 calc((100% - var(--container-width)) / 2);
`;

export const Text = styled.div`
`;

export const Title = styled.h2`
  ${ fonts.displaym200 };
  padding: 6rem 0 2rem;
  position: sticky;
  top: 0rem;
  z-index: 2;
  background-color: ${ g100() };
`;

export const InnerFlex = styled.div`
  padding-top: 4.8rem;
  display: flex;
  align-items: stretch;
  position: relative;
  margin-bottom: 10rem;
`;

export const Controls = styled.div<{topPosition: number}>`
  list-style: none;
  margin-right: 6rem;

  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-start;

  position: sticky;
  top: ${ (pr) => pr.topPosition }rem;
  z-index: 1;

  ${ media.max(900) } {
    top: 22rem;
  }

${ media.max(800) } {
  top: 27rem;
  }
`;

interface ColorProps {
accentColor: string;
}

export const Control = styled.h3<ColorProps>`
  ${ fonts.bodym300 };
  text-transform: uppercase;
  padding: 5px 0 5px 8px;
  border-left: 4px solid ${ (pr) => pr.accentColor };

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  opacity: 1;
`;

export const Content = styled.div`
flex-grow: 0;
flex-shrink: 0;
flex-basis: 45rem;

${ media.max(1152) } {
   flex-basis: 40rem;
  }
${ media.max(800) } {
   flex-basis: 22rem;
  }
`;

export const MarkdownWrapper = styled.div`
  height: 68rem;
  padding-right: 5rem;
`;

export const RichTextEx = styled(RichText)`
  position: relative;
`;

export const ImageWrapper = styled.div`
flex-shrink: 0;
flex-grow: 0;
flex-basis: 44.8rem;
height: 64.8rem;
`;

export const ImageRef = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 6rem;
`;

export const Image = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 100%;
`;
