export const calculateColumnWidth = (
  columnCount: number,
): string => {
  const gutter = 2;

  if (columnCount < 4) {
    return `calc((100% / ${
      columnCount
    }) - (${
      gutter
    }rem * ${
      columnCount - 1
    }))`;
  }
  return `calc(25% - ${ gutter }rem)`;
};
